import React, { useEffect, useContext, useState } from "react"
import styled from "styled-components"
import dayjs from "dayjs"
import { navigate } from "gatsby"
import { useTranslation } from "react-i18next"
import { useLocation } from "@reach/router"
import { CountryRegionData } from "react-country-region-selector"
import { MEDIA_MIN_MEDIUM } from "../constants"
import Layout from "../components/Layout"
import SecureLayout from "../components/SecureLayout"
import Item from "../components/cart/Item"
import Totals from "../components/order/Totals"
import { BrinkContext } from "../components/context/BrinkContext"
import * as events from "../components/context/utils/events"

const Container = styled.div`
  max-width: 70rem;
  margin: 2rem 2rem 0;
  padding: 0;

  ${MEDIA_MIN_MEDIUM} {
    margin: 2rem auto 0;
  }
`

const PageTitle = styled.h1`
  padding: 2rem 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  ${MEDIA_MIN_MEDIUM} {
    padding: 3rem 3rem 0;
  }

  i {
    color: ${(p) => p.theme.colors.success};
    font-size: 3rem;
    margin: 0.4rem 1rem 0 0;
    display: block;
  }
`

const PageSubTitle = styled.p`
  text-align: center;
  padding: 0;
  font-size: 1.6rem;
  text-transform: uppercase;
  margin-top: 0;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.6rem;
  }
`

const ConfirmationTitle = styled.p`
  text-align: center;
  line-height: 2rem;
`

const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 70rem;
  margin: 3rem 0;
  background: ${(p) => p.theme.colors.white};
  border: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  padding: 2rem 2rem 1.8rem;

  h3 {
    width: 100%;
    padding: 0 1rem;
    font-size: 1.6rem;
    margin: 1rem 0 3rem;
    border-left: 0.5rem solid ${(p) => p.theme.colors.primary};

    ${MEDIA_MIN_MEDIUM} {
    }
  }
`

const Row = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  border-top: 0.1rem solid ${(p) => p.theme.colors.lightBorder};

  &:first-child {
    border: none;
  }
`

const Title = styled.p`
  text-transform: uppercase;
  font-size: 1.3rem;
  display: inline-block;

  ${MEDIA_MIN_MEDIUM} {
    display: block;
  }
`

const Value = styled.p`
  font-size: 1.3rem;
  text-transform: uppercase;
  display: inline-block;
`

const Summary = styled(Box)`
  position: relative;

  ${MEDIA_MIN_MEDIUM} {
  }
`

const Items = styled.div`
  width: 100%;
`

const CartTotals = styled(Totals)`
  > div {
    &:first-child {
      border-top: none;
    }
    &:last-child {
      border-bottom: none;

      ${MEDIA_MIN_MEDIUM} {
        border-bottom: none;
      }
    }
  }
`

const AddressWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3rem;
`

const Address = styled(Box)`
  margin-top: 0;

  ${MEDIA_MIN_MEDIUM} {
    &:first-child {
      width: calc(50% - 1rem);
      margin-right: 1rem;
    }

    &:last-child {
      width: calc(50% - 1rem);
      margin-left: 1rem;
    }
  }
`

const SuccessPage = ({ pageContext }) => {
  const { t } = useTranslation("translations")
  const location = useLocation()
  const { currentStore, discountCode, closeCart, getOrderConfirmation } =
    useContext(BrinkContext)
  const [order, setOrder] = useState(null)

  const useMountEffect = (fun) =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fun, [])

  const query = new URLSearchParams(location.search)
  const orderId = query.get("orderId")
  const signature = query.get("signature")
  const resultCode = query.get("resultCode")

  const {
    order: stateOrder,
    billingAddress,
    cart,
    resultCode: stateResultCode
  } = location.state ?? {}

  const handleTracking = (order) => {
    try {
      events.purchase({
        order,
        cart: order.cart,
        discountCode,
        email: order.email ?? order.billingAddress.email,
        currentStore
      })
    } catch (error) {
      console.log(error)
    }
  }


  useMountEffect(() => {
    const run = async () => {
      let order
      if (orderId && signature) {
        order = await getOrderConfirmation(orderId, signature).catch(
          (error) => {
            console.error("get order full error:", error)
            return navigate("/")
          }
        )
      } else {
        order = {
          ...stateOrder,
          person: billingAddress,
          cart: cart
        }
      }
      if (order) {
        closeCart()
        setOrder(order)
        handleTracking(order)
      }
    }

    run()
  })

  if (!order) return null

  const loginEnabled = process.env.GATSBY_VIP_ENABLE_LOGIN === "true"
  const LayoutComponent = loginEnabled ? SecureLayout : Layout
  const { shippingAddress } = order

  const getFullCountryName = (countryCode) =>
    CountryRegionData.find((country) => country[1] === countryCode)

  return (
    <LayoutComponent
      meta={{ title: t("Order confirmation") }}
      invertedHeader
      hideCountrySelector
      pageContext={pageContext}
    >
      <Container>
        <PageTitle>
          <i className="fal fa-check-circle"></i>
          {t("Thank you")}
        </PageTitle>
        <PageSubTitle>
          {resultCode?.toLowerCase() === "authorised" ||
            stateResultCode?.toLowerCase() === "authorised"
            ? t("Your order has been authorised.")
            : t(
              "Your order has been received, wait for the confirmation email for the final result."
            )}
        </PageSubTitle>
        <ConfirmationTitle>
          {t("An order confirmation has been sent to")}{" "}
          <strong>{shippingAddress.email}</strong>
        </ConfirmationTitle>
        <Box>
          <Row>
            <Title>{t("Order number")}:</Title>
            <Value>{order.reference}</Value>
          </Row>
          <Row>
            <Title>{t("Date")}:</Title>
            <Value>
              {dayjs(order.lastUpdated || Date.now()).format("MMMM DD, YYYY")}
            </Value>
          </Row>
          <Row>
            <Title>{t("Payment method")}:</Title>
            <Value>{t(`Payment method ${order.paymentMethod}`)}</Value>
          </Row>
        </Box>
        <Summary>
          <h3>{t("Order summary")}</h3>
          <Items>
            {order.cart.cartItems.map((item) => {
              const orderLine = order.orderLines.find(
                (orderLine) => orderLine.productId === item.id
              )
              return (
                orderLine && (
                  <Item
                    item={{ ...item, quantity: orderLine.quantity }}
                    key={item.id}
                    showControls={false}
                  />
                )
              )
            })}
          </Items>
          <CartTotals order={order} />
        </Summary>
        <AddressWrapper>
          <Address>
            <h3>{t("Billing address")}</h3>
            <div>
              {order.person.givenName ?? order.person.firstName}{" "}
              {order.person.familyName ?? order.person.lastName}
              <br />
              {order.person.streetAddress ?? order.person.address}
              <br />
              {order.person.postalCode} {order.person.city}
              {order.person.region && (
                <>
                  <br />
                  {order.person.region}
                </>
              )}
              , {getFullCountryName(order.person.country)[0]}
              <br />
              {order.person.phone}
            </div>
          </Address>
          <Address>
            <h3>{t("Shipping address")}</h3>
            <div>
              {shippingAddress.givenName} {shippingAddress.familyName}
              <br />
              {shippingAddress.streetAddress}
              <br />
              {shippingAddress.postalCode} {shippingAddress.city}
              {shippingAddress.region && (
                <>
                  <br />
                  {shippingAddress.region}
                </>
              )}
              , {getFullCountryName(shippingAddress.country)[0]}
              <br />
              {shippingAddress.phone}
            </div>
          </Address>
        </AddressWrapper>
      </Container>
    </LayoutComponent>
  )
}

export default SuccessPage
